[data-draggable] {
  user-select: none;
  touch-action: manipulation;
}

html.ui-dragging {
  touch-action: none;

  * {
    cursor: var(--ui-drag-cursor) !important;
  }

  iframe {
    pointer-events: none;
  }
}
