.event-places {
  position: fixed;
  top: 10px;
  right: 10px;
  background: rgb(0 0 0 / 80%);
  color: #fff;
  width: 50vw;
  min-width: 300px;
  max-width: 400px;
  display: grid;
  grid-template-columns: 1fr 5ch;
  column-gap: 15px;
  border-radius: 6px;
  -webkit-font-smoothing: antialiased;
  pointer-events: none;
  line-height: 1.4;
  transition:
    opacity 250ms,
    transform 250ms;
  backdrop-filter: blur(8px);

  transform-origin: calc(100% + 10px) -10px;
  transition-timing-function: var(--ease-in-cubic);
  box-shadow: 0px 1px 2px rgb(0 0 0 / 50%);
  font-size: 18px;
  font-weight: 500;

  // State
  opacity: 0;
  transform: scale(0.5);

  &--active {
    opacity: 1;
    transform: scale(1);
    pointer-events: auto;
    transition-timing-function: var(--ease-out-cubic);
  }

  &-place {
    display: grid;
    cursor: pointer;
    grid-template-columns: subgrid;
    grid-column: 1 / -1;
    padding: 15px;

    &:hover {
      background: rgb(255 255 255 / 10%);
    }
  }
}
