.event-list {
  position: fixed;
  top: 10px;
  right: 10px;
  background: rgb(0 0 0 / 80%);
  color: #fff;
  width: 50vw;
  min-width: 300px;
  max-width: 400px;
  display: grid;
  grid-template-columns: 1fr 5ch;
  column-gap: 15px;
  border-radius: 6px;
  -webkit-font-smoothing: antialiased;
  pointer-events: none;
  line-height: 1.4;
  transition:
    opacity 250ms,
    transform 250ms;
  backdrop-filter: blur(8px);
  max-height: calc(100dvh - 100px);
  overflow-y: auto;

  transform-origin: calc(100% + 10px) -10px;
  transition-timing-function: var(--ease-in-cubic);
  box-shadow: 0px 1px 2px rgb(0 0 0 / 50%);

  // State
  opacity: 0;
  transform: scale(0.5);

  &--active {
    opacity: 1;
    transform: scale(1);
    pointer-events: auto;
    transition-timing-function: var(--ease-out-cubic);
  }

  &-event {
    display: grid;
    cursor: pointer;
    grid-template-columns: subgrid;
    grid-column: 1 / -1;
    padding: 15px 20px;

    &:hover {
      background: rgb(255 255 255 / 10%);
    }
  }

  &-empty {
    padding: 10px 20px;
    text-align: center;
    grid-column: 1 / -1;
    opacity: 0.5;
  }

  &-place {
    grid-column: 1;
    display: flex;
    flex-flow: column;
    gap: 3px;
  }

  &-magnitude {
    grid-column: 2;
    text-align: right;
  }

  &-date {
    // opacity: 0.5;
    color: rgb(255 255 255 / 40%);
    font-size: 14px;
    font-weight: 500;
  }
}
