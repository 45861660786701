.timeline {
  --bg: rgb(0 0 0 / 50%);
  --active-bg: rgb(0 0 0 / 90%);

  color: #fff;
  position: fixed;
  inset: auto 0 15px 0;
  width: 80vw;
  margin: auto;
  display: flex;
  gap: 5px;
  -webkit-font-smoothing: antialiased;

  &-play-button {
    background-color: var(--bg);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    width: 44px;
    cursor: pointer;
    touch-action: manipulation;
    user-select: none;
    flex: none;

    svg {
      width: 18px;
      height: 18px;
    }
  }

  &-list,
  &-places,
  &-faults,
  &-fullscreen {
    background-color: var(--bg);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    width: 44px;
    cursor: pointer;
    touch-action: manipulation;
    user-select: none;
    flex: none;

    svg {
      width: 18px;
      height: 18px;
    }

    &--active {
      background-color: var(--active-bg);
    }

    &--disabled {
      opacity: 0.1;
      pointer-events: none;
    }
  }

  &-speeds {
    background-color: var(--bg);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    width: 44px;
    user-select: none;
    position: relative;
    font-weight: bold;
    flex: none;

    &-toggle {
      position: absolute;
      inset: 0;
      left: 0;
      border-radius: 4px;
      cursor: pointer;
      touch-action: manipulation;
    }
  }

  &-track {
    flex: auto;
    background: var(--bg);
    position: relative;
    border-radius: 4px;
    cursor: col-resize;
    backdrop-filter: blur(8px);
  }

  &-slider {
    position: absolute;
    top: -10px;
    width: 44px;
    height: calc(100% + 10px);
    transform: translateX(-50%);
    cursor: grab;

    &::after {
      content: '';
      position: absolute;
      left: 50%;
      height: 100%;
      width: 3px;
      transform: translateX(-50%);
      background: #3390e9;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      z-index: 100;
    }
  }

  &-ticks {
    display: flex;
    justify-content: space-around;
    height: 100%;
  }

  &-tick {
    width: 2px;
    height: 20%;
    background-color: rgb(255 255 255 / 50%);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    &:nth-child(5n) {
      height: 40%;
    }
  }

  &-biggest {
    position: absolute;
    inset: 0;
  }

  &-big-event {
    position: absolute;
    width: 5px;
    bottom: 0;
    transform: translateX(-50%);
    background-color: #1a96e4;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    touch-action: manipulation;
    cursor: pointer;

    &:hover {
      opacity: 1 !important;

      & .timeline-big-event-popup {
        opacity: 1;
        transform: translateX(-50%) scale(1);
      }
    }

    &-popup {
      --duration: 300ms;
      position: absolute;
      z-index: 1000;
      bottom: calc(120% + 4px);
      padding: 5px 10px;
      left: 50%;
      opacity: 0;
      transform: translateX(-50%) scale(0);
      pointer-events: none;
      background: #fff;
      color: #000;
      border-radius: 4px;
      transform-origin: 50% 150%;
      transition: transform, opacity;
      transition-duration: var(--duration);
      transition-timing-function: var(--ease-in-back);
      width: 25ch;
      text-align: center;
      line-height: 1.4;
      -webkit-font-smoothing: none;
      filter: drop-shadow(0px 1px 2px rgb(0 0 0 / 50%));

      &--active {
        transform: translateX(-50%) scale(1);
        transition-timing-function: var(--ease-out-back);
        opacity: 1;
      }

      &::after {
        content: '';
        position: absolute;
        transform: translateX(-50%) rotate(45deg);
        background-color: #fff;
        bottom: -3px;
        left: 50%;
        width: 8px;
        height: 8px;
      }

      // opacity: 0;
    }
  }

  &-date {
    --duration: 300ms;
    position: absolute;
    left: 50%;
    bottom: calc(100% + 8px);
    padding: 5px 10px;
    transform: translateX(-50%);
    background: var(--bg);
    border-radius: 4px;
    white-space: nowrap;
    width: 6.7ch;
    padding: 2px 10px;
    text-align: right;
    font-size: 15px;
    font-weight: 500;
  }

  &-helper {
    --duration: 300ms;
    position: absolute;
    z-index: 1000;
    font-size: 22px;
    bottom: calc(120% + 8px);
    padding: 5px 10px;
    left: 50%;
    opacity: 0;
    transform: translateX(-50%) scale(0);
    background: var(--bg);
    border-radius: 4px;
    transform-origin: 50% 150%;
    transition: transform, opacity;
    transition-duration: var(--duration);
    transition-timing-function: var(--ease-in-back);
    white-space: nowrap;
    text-align: right;
    width: 7ch;

    &--active {
      transform: translateX(-50%) scale(1);
      transition-timing-function: var(--ease-out-back);
      opacity: 1;
    }

    &::after {
      content: '';
      position: absolute;
      transform: translateX(-50%) rotate(45deg);
      background-color: var(--bg);
      bottom: -3px;
      left: 50%;
      width: 8px;
      height: 8px;
    }
  }

  &-speeds,
  &-play-button,
  &-list,
  &-places,
  &-faults,
  &-fullscreen {
    aspect-ratio: 1.1;
  }

  @media (width < 600px) {
    display: grid;
    width: 100vw;
    bottom: 0;
    grid-template-columns: repeat(8, 1fr);
    background: #0b4560;
    box-sizing: border-box;
    gap: 3px;
    padding: 3px;

    &-speeds,
    &-play-button,
    &-list,
    &-places,
    &-faults,
    &-fullscreen {
      aspect-ratio: 1.3;
    }

    &-play-button {
      grid-column: 1;
      grid-row: 1;
    }

    &-speeds {
      grid-column: 1;
      grid-row: 2;
    }

    &-track {
      grid-column: 2 / 7;
      grid-row: 1 / 3;
    }

    &-list,
    &-places {
      grid-row: 1;
    }

    &-faults,
    &-fullscreen {
      grid-row: 2;
    }

    &-list,
    &-faults {
      grid-column: 7;
    }

    &-places,
    &-fullscreen {
      grid-column: 8;
    }
  }
}
