:root {
  --ease-in-back: cubic-bezier(0.6, -0.28, 0.735, 0.045);
  --ease-out-back: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  --ease-in-cubic: cubic-bezier(0.32, 0, 0.67, 0);
  --ease-out-cubic: cubic-bezier(0.33, 1, 0.68, 1);
  --ease-in-out-cubic: cubic-bezier(0.65, 0, 0.35, 1);
}

html,
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, system-ui, 'Helvetica Neue', Arial, Helvetica;
  line-height: 1.6;
}

html,
body,
#root {
  height: 100%;
}

.vAygCK-api-load-alpha-banner {
  display: none;
}
